<template>
  <Dialog
    :visible="visible"
    :style="{ width: '50vw', 'max-width': '500px' }"
    :breakpoints="{'640px': '100vw'}"
    :closable="false"
    :header="`Disconnect Zoom Integration?`"
  >
    <div class="dialog-body">
      <p>Are you sure you want to disconnect your Zoom integration? Your Zoom recording transcripts will no longer be automatically retrieved. If you wish to disconnect zoom, we also suggest removing the Seekit app from your Zoom account. </p>
    </div>

    <div class="dialog-buttons">
      <loading-spinner
        v-if="$isLoading('deleteZoomIntegration')"
        :size-class="'fa-2x'"
      />

      <button
        :disabled="$isLoading('deleteZoomIntegration')"
        @click="closeDialog"
      >
        Cancel
      </button>

      <button
        class="btn-primary"
        :disabled="$isLoading('deleteZoomIntegration')"
        @click="submit"
      >
        Disconnect
      </button>
    </div>
  </Dialog>
</template>

<script setup>
import { ref, getCurrentInstance } from 'vue';
import { useStore } from 'vuex';
import { loadable } from 'vue-is-loading';
import { getErrorMessage } from '#lib/Errors';
import { successToast, errorToast } from '#ui/lib/toast';
import LoadingSpinner from '#ui/components/LoadingSpinner';

// Setup

const store = useStore();

// Open/close

const visible = ref(false);
const openDialog = () => {
  visible.value = true;
};
const closeDialog = () => {
  visible.value = false;
};
defineExpose({ openDialog, closeDialog });

// Delete

const deleteZoomIntegration = loadable(
  (values) => store.dispatch('ZoomIntegrations/deleteZoomIntegration'),
  'deleteZoomIntegration',
  getCurrentInstance(),
);
const submit = async () => {
  try {
    await deleteZoomIntegration();
    const name = 'Zoom';
    await store.dispatch('Integrations/disconnectIntegration', { name });
    closeDialog();
    successToast('Zoom Integration disconnected.');
  }
  catch (error) {
    errorToast(getErrorMessage(error, 'Could not disconnect Zoom Integration.'));
  }
};
</script>
