<template>
  <Dialog
    :visible="visible"
    :style="{ width: '50vw', 'max-width': '500px' }"
    :breakpoints="{'640px': '100vw'}"
    :closable="false"
    header="Connect With Zoom"
  >
    <div class="connect-instructions">
      <p class="intro-text">
        Get ready to level up your Zoom meetings! By connecting your Zoom account with Seekit, you'll be able to tap into our powerful analysis tools and get insights from every recorded call. Here's how to connect:
      </p>
      <ul class="steps-list">
        <li>
          <span>Step 1:</span>
          <br>
          Check that your Zoom account uses the same email address as your Seekit account.
        </li>
        <li>
          <span>Step 2:</span>
          <br>
          <a
            target="_blank"
            :href="url"
            class="cta-link"
          >
            Connect the Seekit app for Zoom to your Zoom account.
          </a>
        </li>
        <li>
          <span>Step 3:</span>
          <br>Record your meetings and save them to the cloud (you'll need a paid Zoom account for this).
        </li>
      </ul>
      <p class="outro-text">
        Once that's done, each new meeting you record will be analyzed automatically - harness the power of your conversations like never before.
      </p>
    </div>
    <div class="dialog-buttons">
      <button
        class="btn-secondary"
        @click="closeDialog"
      >
        Cancel
      </button>
      <a
        target="_blank"
        :href="url"
      >
        <button class="btn-primary">
          Connect Now
        </button>
      </a>
    </div>
  </Dialog>
</template>

<script setup>
import { ref, onMounted, getCurrentInstance, computed } from 'vue';
import { useStore } from 'vuex';
import { loadable } from 'vue-is-loading';

const store = useStore();
const getZoomUrl = loadable(
  (values) => store.dispatch('ZoomIntegrations/getUrl'),
  'getZoomUrl',
  getCurrentInstance(),
);

// Open/close
const visible = ref(false);
const url = computed(() => store.state.ZoomIntegrations.zoomUrl);

const openDialog = () => {
  visible.value = true;
};
const closeDialog = () => {
  visible.value = false;
};
defineExpose({ openDialog, closeDialog });

onMounted(async () => {
  await getZoomUrl();
});

</script>

<style scoped>
  .connect-instructions {
    font-size: 16px;
    color: #333;
    line-height: 1.6;
  }

  .intro-text, .outro-text {
    margin-bottom: 25px;
  }

  .steps-list {
    list-style-type: none;
    padding: 0;
    margin-bottom: 25px;
  }

  .steps-list li {
    margin-bottom: 15px;
    padding-left: 10px;
    position: relative;
  }

  .steps-list li span {
    font-weight: bold;
    margin-right: 10px;
    position: absolute;
    left: 0;
    top: 0;
  }
  .cta-link, .connect-button {
    color: #007BFF;
    text-decoration: none;
    transition: color 0.3s;
  }

  .cta-link:hover, .connect-button:hover {
    color: #0056b3;
  }

  .connect-button {
    display: inline-block;
    background-color: #007BFF;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    text-align: center;
    margin: 0 auto;
    transition: background-color 0.3s;
  }

  .connect-button:hover {
    background-color: #0056b3;
  }
</style>
